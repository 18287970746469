// Pages
import React, { useState } from 'react';
import Inicio from './components/pages/Inicio';
import Home from './components/pages/Home'
function App() {

  const [conectado, setConectado] = useState(false);

  const acceder = (estado) => {
    setConectado(estado)
  }
  return (

    conectado ? <Inicio /> : <Home acceder={acceder} />

  );
}

export default App;
