import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';



export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiOutlineAliwangwang />,
    cName: 'nav-text'
  },
  {
    title: 'CLASES RESERVADAS',
    path: '/clases',
    icon: <IoIcons.IoMdFlashlight />,
    cName: 'nav-text'
  },
  {
    title: 'PERFIL',
    path: '/perfil',
    icon: <FaIcons.FaUser />,
    cName: 'nav-text'
  },
  {
    title: 'RECURSOS',
    path: '/recursos',
    icon: <IoIcons.IoIosBatteryDead />,
    cName: 'nav-text'
  },
  {
    title: 'Mensajes',
    path: '/mensajes',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
  {
    title: 'AYUDA',
    path: '/ayuda',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  }
];