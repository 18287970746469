import React, { useState } from 'react'
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import { useForm } from 'react-hook-form';
import '../assets/Citas.css';

export default function Acceso() {
    const baseUrl = "https://api-appconingles.app-coningles.com/reservas.php";
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        console.log(data);
        fetch(baseUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then(resp => resp.json())
            .then(json => {
                console.log("respuesta", json);
            })
    }

    console.log(errors)

    const [estadoModal1, cambiarEstadoModal1] = useState(false)

    return (
        <div className="BodyRegister">
            <div className="MainContainerRegister">
                <ContenedorBotones>
                    <Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>Gomez Palacio</Boton>
                    <Boton>Independencia </Boton>
                    <Boton>Saltillo 400</Boton>
                    <Boton>Durango</Boton>
                    <Boton>Ibero</Boton>
                </ContenedorBotones>
                <Modal
                    estado={estadoModal1}
                    cambiarEstado={cambiarEstadoModal1}
                >              
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="InputContainerRegister">
                                <label>Nombre Completo</label>
                                <input type="text" placeholder="Nombre Completo" {...register("nombre", {})} className="StyledInputCitas" />
                                <label>Sucursal</label>
                                <select {...register("sucursal", { required: true })} className="StyledInputCitas">
                                  <option value="Gomez Palacio">Gomez Palacio</option>
                                </select>
                                <label>Fecha & Hora</label>
                                <input type="datetime-local" placeholder="Horario" {...register("fechahora", { required: true })} className="StyledInputCitas" />
                                <label>Teléfono</label>
                                <input type="text" placeholder="Teléfono" {...register("telefono", { required: true })} className="StyledInputCitas" />
                                <div className="ButtonContainer">
                                    <input type="submit" className="StyledButton"/>
                              </div>
                            </div>
                        </form>
                  
                   
                </Modal>
            </div>
        </div>

    )
}

const ContenedorBotones = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;
   flex-wrap: wrap;
   padding: 30px;
   margin-left: 0;
`;

const Boton = styled.button`
	display: block;
	padding: 10px 30px;
	border-radius: 100px;
	color: #fff;
	border: none;
	background: #1766DC;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	transition: .3s ease all;
	&:hover {
		background: #0066FF;
	}
`;
