import React from 'react';
import Navbar from '../Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Acceso from '../pages/Acceso';
import Ayuda from '../pages/Ayuda';
import Clases from '../pages/Clases';
import Perfil from '../pages/Perfil';
import Recursos from '../pages/Recursos'
import Mensajes from '../pages/Mensajes'

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
        <Route path='/' exact element={<Acceso />} />
          <Route path='/Ayuda' element={<Ayuda />} />
          <Route path='/Clases' element={<Clases />} />  
          <Route path='/Perfil' element={<Perfil />} />   
          <Route path='/Recursos' element={<Recursos />} />     
          <Route path='/Mensajes' element={<Mensajes />} />       
        </Routes>
      </Router>
    </>
  );
}

export default App;